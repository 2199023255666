exports.components = {
  "component---cache-wapps-redirect-js": () => import("./../../@wapps/redirect.js" /* webpackChunkName: "component---cache-wapps-redirect-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cancel-index-jsx": () => import("./../../../src/pages/cancel/index.jsx" /* webpackChunkName: "component---src-pages-cancel-index-jsx" */),
  "component---src-pages-cancel-success-jsx": () => import("./../../../src/pages/cancel/success.jsx" /* webpackChunkName: "component---src-pages-cancel-success-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-request-index-jsx": () => import("./../../../src/pages/request/index.jsx" /* webpackChunkName: "component---src-pages-request-index-jsx" */),
  "component---src-pages-request-success-jsx": () => import("./../../../src/pages/request/success.jsx" /* webpackChunkName: "component---src-pages-request-success-jsx" */)
}

